import React from "react";
import successfulLogo from "../../assets/successful.gif";
import { useNavigate } from "react-router-dom";

const SucceededPage2 = () => {
  const navigate = useNavigate();

  const handleReturnHome = () => {
    navigate("/");
  };

  return (
    <div
      style={{
        backgroundColor: "#f8f9fa", // Light background color
        minHeight: "100vh", // Full screen height
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <main style={{ width: "100%", maxWidth: "600px" }}>
        <div
          className="container"
          style={{
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            overflow: "hidden",
            backgroundColor: "#fff",
            padding: "40px",
            textAlign: "center",
          }}
        >
          <div className="title">
            <h2
              className="fw-bold"
              style={{
                fontSize: "2rem", // Larger font size
                color: "#333",
                fontWeight: "800", // Extra bold
                marginBottom: "10px",
              }}
            >
              عبدالرحمن ستورز
            </h2>
            <p
              className="text-secondary fs-4"
              style={{
                fontSize: "1.2rem", // Slightly larger font size
                color: "#666",
                fontWeight: "700", // Bold text
              }}
            >
              اختر منتجاتك المفضلة من المكملات الغذائية لتحسين صحتك وأدائك
              الرياضي
            </p>
          </div>
          <div>
            <div>
              <img
                src={successfulLogo}
                className="img-fluid"
                style={{
                  width: "120px",
                  margin: "20px auto",
                  display: "block",
                }}
                alt="Success"
              />
              <h2
                className="fw-bold"
                style={{
                  fontSize: "1.8rem", // Slightly larger font size
                  color: "#28a745",
                  fontWeight: "800", // Extra bold
                  marginTop: "20px",
                }}
              >
                تم تأكيد طلبك
              </h2>
              <p
                className="fs-4 text-secondary"
                style={{
                  fontSize: "1.2rem", // Slightly larger font size
                  color: "#555",
                  fontWeight: "700", // Bold text
                  marginTop: "10px",
                  lineHeight: "1.8",
                }}
              >
                تم ارسال طلب انشاء الحساب بنجاح <br />
                📞 سيتم التواصل معك فى اقرب وقت لاعتماد طلبك <br />
                💬 لأى استفسار تواصل معنا واتس اب على الرقم التالى:
                <span style={{ color: "#007bff", fontWeight: "bold" }}>
                  01102886364
                </span>
              </p>
              <button
                onClick={handleReturnHome}
                style={{
                  backgroundColor: "#28a745",
                  color: "#fff",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  border: "none",
                  fontSize: "1.2rem", // Larger font size
                  fontWeight: "700", // Bold button text
                  marginTop: "20px",
                  cursor: "pointer",
                }}
              >
                العودة للموقع
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SucceededPage2;
